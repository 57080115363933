<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
/**
 * Page-terms component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100" style="padding-top: 50px; padding-bottom: 50px;">
        <div class="container">
            <div class="row justify-content-center mt-5">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> Términos y Condiciones del Servicio</h4>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Terms & Conditions -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow border-0 rounded">
                        <div class="card-body">
                            <h5 class="card-title">1: Edad del Solicitante</h5>
                            <p class="text-muted">Para solicitar este servicio, el paciente debe ser mayor de 18 años. En el caso de menores de edad, deberá ser solicitado por los padres adjuntando un documento que certifique su condición de tutor legal.</p>
                            
                            <h5 class="card-title">2: Plazo e Inicio del Servicio</h5>
                            <p class="text-muted">El plazo para la realización de la <b class="text-primary ">Segunda Opinión </b>médica es de 3 días hábiles. El proceso se iniciará una vez que haya efectuado el pago y/o haber presentado el cupón canjeable de servicio gratuito, y cuando el usuario reciba el acuse de documentación completa por parte del coordinador médico.</p>

                            <h5 class="card-title">3: Métodos de Pago</h5>
                            <p class="text-muted">
                                El precio de la segunda opinión, deberá ser cubierta mediante transferencia bancaria, pago con tarjetas de crédito y débito, pagos en efectivo realizados en las tiendas Oxxo por las cantidades y la cuenta bancaria que en su momento se le indique.
                            </p>

                            <h5 class="card-title">4: Alcance del Servicio</h5>
                            <p class="text-muted">
                                El importe incluye los costos de la valoración médica, el entregable de la segunda opinión en formato escrito y video-grabación, una o máximo dos llamadas y/o video conferencia con el coordinador médico para aclaraciones y resolución de dudas, si así lo desea.
                            </p>

                            <h5 class="card-title">5: Veracidad de la Información</h5>
                            <p class="text-muted">
                                La Segunda Opinión está condicionada a la veracidad de la información facilitada a la Plataforma <b class="text-primary ">Duppla</b>. Además, este documento tiene un carácter meramente informativo, sin que se considere diagnóstico o consejo médico, ni lleve al paciente a abandonar o modificar el tratamiento anterior sin previamente consultar con un profesional de la salud en una consulta formal.
                            </p>

                            <h5 class="card-title">6: Idioma de la Información</h5>
                            <p class="text-muted">
                                La documentación clínica que envíe (informes médicos, analíticas, resultados de pruebas de laboratorio y/o de imagen…) deberá ser en español o en inglés para facilitar su revisión por el especialista.
                            </p>

                            <h5 class="card-title">7: Derechos Reservados</h5>
                            <p class="text-muted">
                                La Plataforma <b class="text-primary ">Duppla</b> se reserva el derecho de aceptar o rechazar cualquier solicitud de Segunda Opinión, lo que se comunicará oportunamente en un plazo de 2 días hábiles, realizándose el reembolso del importe abonado, en caso de que este haya sido cubierto.
                            </p>

                            <h5 class="card-title">8: Documentación</h5>
                            <p class="text-muted">
                                Si la documentación recibida no fuera suficiente para emitir una segunda opinión, un representante de <b class="text-primary ">Duppla</b> se pondrá en contacto con el paciente o representante legal para solicitar el resto de la información necesaria, misma que deberá ser entregada en un lapso de cinco días hábiles, salvo que dicha información no se le pueda entregar al paciente dentro del tiempo estipulado, por situaciones ajenas al pacientes y que dependan de terceras personas, las cuales, deberán ser acreditadas y expedidas por la Clínica, médico y/o especialista que emitirá el informe médico.
                            </p>

                            <h5 class="card-title">9: Falta de Documentación</h5>
                            <p class="text-muted">
                                Si no fuera posible aportar esta información, en tiempo y forma salvo lo anteriormente mencionado en el número 8-ocho se procederá al reembolso del importe abonado.
                            </p>

                            <h5 class="card-title">10: Facilitar la modificación y/o actualización de datos asociados a la cuenta</h5>
                            <p class="text-muted">
                                El paciente tiene la posibilidad de modificar y/o actualizar a través de la plataforma sus datos asociados a su perfil en el Área de Paciente, tales como el número de teléfono, dirección del correo electrónico, el número de póliza de seguro o domiciliación bancaria de sus recibos correspondientes a los servicios contratados, entre otros. En caso de que el usuario haga uso de tales funcionalidades, los datos personales serán tratados con la finalidad de atender la gestión en cada caso solicitada por el usuario.
                            </p>

                            <h5 class="card-title">11: Facilitar telemáticamente la segunda opinión</h5>
                            <p class="text-muted">
                                <b class="text-primary ">Duppla</b> habilitará el acceso telemático (digital) a su segunda opinión toda información resultado del servicio. En tales casos, los datos personales serán tratados con la finalidad de proporcionar al usuario (paciente) el servicio. <b class="text-primary ">Duppla</b> mantiene implementadas medidas de seguridad adecuadas para garantizar la integridad y confidencialidad de los datos médicos y clínicos de los pacientes.
                            </p>

                            <h5 class="card-title">12: Facilitar telemáticamente los datos de facturación</h5>
                            <p class="text-muted">
                                El paciente tiene la posibilidad de acceder, a las facturas emitidas por <b class="text-primary ">Duppla</b>, por la prestación de los servicios contratados. <b class="text-primary ">Duppla</b> tratará datos económicos y relativos a transacciones de servicios para la consecución de este fin.
                            </p>

                            <h5 class="card-title">13: Lugar</h5>
                            <p class="text-muted">
                                Los servicios de <b class="text-primary ">Duppla</b> podrán ser prestados a distancia a través de tecnologías de la información. Para esta finalidad <b class="text-primary ">Duppla</b> tratará datos relativos a la salud del paciente e identificativos, así como de sus circunstancias personales y sociales para alcanzar las finalidades pretendidas. <b class="text-primary ">Duppla</b> mantiene implementadas medidas de seguridad adecuadas para garantizar la integridad y confidencialidad de los datos médicos y clínicos de los pacientes.
                            </p>

                            <h5 class="card-title">14: Plataforma de documentación Online</h5>
                            <p class="text-muted">
                                Le informamos que la información puesta a su disposición mediante la presente plataforma permanecerá disponible en la misma únicamente durante el plazo de 90 días hábiles, pasado el cual, se procederá a su borrado en dicha plataforma. <b class="text-primary ">Duppla</b> tratará sus datos personales para la gestión y tramitación correcta de su solicitud. Puede acceder a la información completa en su cláusula de paciente y ejercer los derechos de acceso, rectificación, cancelación y oposición (ARCO) en <a href="mailto:privacidad@duppla.doctor" target="_blank">privacidad@duppla.doctor</a>.
                            </p>

                            <h5 class="card-title">15: Política de Privacidad </h5>
                            <p class="text-muted">
                                <b class="text-primary ">Duppla</b> pone a disposición de los usuarios de la página web <a href="/privacidad/" target="_blank">www.duppla.doctor/privacidad</a>, la presente Política de Privacidad, con la finalidad de ofrecer información sobre cómo tratamos sus datos personales y protegemos su privacidad e información. <b class="text-primary ">Duppla</b> solicita al usuario que dedique unos minutos a leer con detenimiento la Política de Privacidad.
                            </p>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Terms & Conditions -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!--Switcher /-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
